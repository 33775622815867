import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  TitleContent,
  WorkedWith,
  ExternalLink,
} from "../components/parts"

const Learnographics = () => (
  <Layout title="Retailkit">
    <Title
      heading="Retailkit"
      sub="Identify influencers among your Instagram fans"
    />
    <Overview
      project="retailkit"
      text={[
        "Retailkit identifies your real fans and influencers among your Instagram followers. We crawl through your Instagram page at scale to identify organic influencers who have engaged with your brand, along with an in-depth analysis of your fans and engagement.",
        "I assembled the Instagram crawler, processed the graph data, and built the visual report.",
      ]}
    />
    <TitleContent title="Sample report">
      <p>
        Analysis on my own{" "}
        <ExternalLink
          href="https://instagram.com/elayabharath"
          label="Instagram page"
        />{" "}
        for illustration
      </p>
      <img src="/images/retailkit/report.png" alt="report" />
    </TitleContent>
    <Visit project="retailkit" />
    <Toolset project="retailkit" />
    <Involvement project="retailkit" />
    <WorkedWith project="retailkit" />
  </Layout>
)

export default Learnographics
